import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import LoginPage from './loginPage';
import CreateAccount from './createAccount';
import PasswordResetPage from './passwordResetPage';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './protectedRoute';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route path="/" element={<LoginPage />} />
//         <ProtectedRoute path="/home">
//           <App />
//         </ProtectedRoute>
//         <Route path="/createAccount" element={<CreateAccount />} />
//         <Route path="/passwordResetPage/:token/:username" element={<PasswordResetPage />} />
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

root.render(
  <React.StrictMode>
    <Router>
      <ProtectedRoute />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
