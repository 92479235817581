import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import Navbar from './navbar';
import './css/createAccount.css';
import Layout from './layout';

const CreateAccount = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        accountNumber: '',
        companyName: '',
        // propertyStreetName: '',
        // propertyStreetNumber: '',
        // propertyCityName: '',
        // propertyStateName: '',
        // propertyZipNumber: '',
        email: ''
    });

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/verify/verifyCredentials', {
            ...credentials,
            username: credentials.email,
        }); // Add the endpoint here

            if (response.data.success) {
                // The credentials were verified and the email was sent
                // Navigate to the next page here
                alert('Password email sent successfully');
            } else {
                // The credentials were not verified
                // Show an error message here
                alert('Error creating account');
            }
        } catch (error) {
            console.error('Error creating account:', error);
            console.log(error.response.data);
        }
    };

    return (
        <Layout>
            <div className="container create-account-container">
                <div className="form-container form-container-create-account">
                    <div className="create-account-inner-container">
                        <h1>Login Registration</h1>
                        <p>Please complete the form below and click Register. An email will be sent to you with a link to set up</p>
                        <h2>User information (Used for verification only)</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formAccountNumber">
                                <Form.Label>Account Id</Form.Label>
                                <Form.Control type="text" name="accountNumber" value={credentials.accountNumber} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formCompanyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" name="companyName" value={credentials.companyName} onChange={handleChange} />
                            </Form.Group>
                            {/* <Form.Group controlId="formPropertyStreetNumber">
                                <Form.Label>Property Street Number</Form.Label>
                                <Form.Control type="text" name="propertyStreetNumber" value={credentials.propertyStreetNumber} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formPropertyStreetName">
                                <Form.Label>Property Street Name</Form.Label>
                                <Form.Control type="text" name="propertyStreetName" value={credentials.propertyStreetName} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formPropertyCityName">
                                <Form.Label>Property City Name</Form.Label>
                                <Form.Control type="text" name="propertyCityName" value={credentials.propertyCityName} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formPropertyStateName">
                                <Form.Label>Property State Name</Form.Label>
                                <Form.Control type="text" name="propertyStateName" value={credentials.propertyStateName} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formPropertyZipNumber">
                                <Form.Label>Property Zip</Form.Label>
                                <Form.Control type="text" name="propertyZipNumber" value={credentials.propertyZipNumber} onChange={handleChange} />
                            </Form.Group> */}
                        </Form>
                        <div className="email-container">
                            <h2>Email (Used for your username)</h2>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" value={credentials.email} onChange={handleChange} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Register
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreateAccount;