import React, { useState, useEffect } from 'react';
import './css/dashboard.css';
import Layout from './layout';

const Dashboard = () => {
    return (
        <Layout>
            <div className="dashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {/* Your content here */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;

