// frontend/src/axiosConfig.js
import axios from 'axios';

// Set up Axios interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.withCredentials = true;
        return config;
    },
    error => Promise.reject(error)
);

export default axios;