// frontend/src/directoryTree.js this is the folder tree component
import React, { useState } from 'react';
import axios from 'axios';
import { FaFolder, FaFolderOpen } from 'react-icons/fa'; // Import folder icons
import PropTypes from 'prop-types'; // Import PropTypes
import './css/directoryTree.css'; // Import custom CSS for styling

const DirectoryTree = ({ directory: initialDirectory, onClick, onDirectoryNameClick = () => {}, isRoot, depth }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [directory, setDirectory] = useState(initialDirectory); // Define directory state

    const handleIconClick = (event) => {
        event.stopPropagation();
        setIsExpanded(!isExpanded);

        if (!isExpanded && !directory.childDirectories) {
            axios.get(`/api/directories?parentId=${directory.id}`)
                .then(res => {
                    const childDirectories = res.data.filter(dir => dir.parentId === directory.id)
                        .map(dir => ({ ...dir, depth: depth + 1 }));
                    setDirectory(prevDirectory => ({
                        ...prevDirectory,
                        childDirectories: childDirectories
                    }));
                })
                .catch(error => console.error(error));
        }
    };

    const handleDirectoryNameClick = (event) => {
        event.stopPropagation();
        onDirectoryNameClick(directory.id);
    };

    return (
        <div className={isRoot ? "directory-tree-container" : ""}> {/* Updated class name */}
            <ul className={`directory-tree-list ${!isRoot ? "child-directory" : ""}`} style={{ paddingLeft: `${depth * 20}px` }}> 
                <li>
                    <span onClick={handleIconClick} className="directory-tree-item"> {/* Updated class name */}
                        {isExpanded ? <FaFolderOpen /> : <FaFolder />}
                    </span>
                    <span onClick={handleDirectoryNameClick} className="directory-tree-item-name"> {/* Updated class name */}
                        {directory.dir_name}
                    </span>
                    {isExpanded && directory.childDirectories && directory.childDirectories.map(childDirectory => (
                        <DirectoryTree key={childDirectory.id} directory={childDirectory} onClick={onClick} onDirectoryNameClick={onDirectoryNameClick} isRoot={false} depth={depth + 1} />
                    ))}
                </li>
            </ul>
        </div>
    );
};

DirectoryTree.propTypes = {
    directory: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDirectoryNameClick: PropTypes.func, // Marking as optional since we provided a default function
    isRoot: PropTypes.bool,
    depth: PropTypes.number
}

export default DirectoryTree;