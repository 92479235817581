import { useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import App from './App';
import LoginPage from './loginPage';
import CreateAccount from './createAccount';
import PasswordResetPage from './passwordResetPage';
import Dashboard from './dashboard';
import Documents from './documents';
import CreatePassword from './createPassword';
import Contact from './contact';
import Home from './home';
import AdminDashboard from './adminDashboard';

function ProtectedRoute() {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const userRole = localStorage.getItem('userRole');

    const routes = useRoutes([
        { path: '/', element: <LoginPage /> },
        // { path: '/', element: <Home /> },
        { path: '/home', element: <Home />},
        { path: '/contact', element: <Contact />},
        { path: '/dashboard', element: isAuthenticated ? <Dashboard /> : <Navigate to="/" /> },
        { path: '/documents', element: isAuthenticated ? <Documents /> : <Navigate to="/" /> },
        { path: '/adminDashboard', element: isAuthenticated && userRole === 'admin' ? <AdminDashboard /> : <Navigate to="/" /> },
        { path: '/createAccount', element: <CreateAccount /> },
        { path: '/passwordResetPage/:token/:username', element: <PasswordResetPage /> },
        { path: '/setPassword/:token/:username', element: <CreatePassword /> },
    ]);

    return routes;
};

export default ProtectedRoute;