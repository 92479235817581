import React from 'react';
import './css/footer.css';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div class="row" style={{ display: 'flex', alignItems: 'center' }}>
                            <div class="col-sm-6">
                                <h1 class="footer-logo">Riverwalk Commerical Park Association, Inc.</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="ftr-logo left">
                                    <p>Managed by GRH Development Resources, LLC</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;