import React, { useEffect, useState } from 'react';
import Layout from './layout';
import './css/adminDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { FaFilePdf, FaFileWord, FaFolder } from 'react-icons/fa';
import DirectoryTree from './directoryTree';
// import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import axios from './axiosConfig';
// import { handleDirectoryClick, handleDirectoryNameClick } from './documents.js';

const AdminDashboard = () => {
    const [loginVerifications, setLoginVerifications] = useState([]);
    const [unassociatedUsers, setUnassociatedUsers] = useState([]);
    const [allAdmins, setAllAdmins] = useState([]);
    const [editRowId, setEditeRowId] = useState(null);
    const [editFormData, setEditFormData] = useState({ company_name: '', account_number: '' });
    const [selectedLoginVerification, setSelectedLoginVerification] = useState({});
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('Regular User');
    const [company, setCompany] = useState('');
    const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [directories, setDirectories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentView, setCurrentView] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [selectedPath, setSelectedPath] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDeleteFile, setSelectedDeleteFile] = useState(null);
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newAccountNumber, setNewAccountNumber] = useState('');

    useEffect(() => {
        fetchData();

        setIsLoading(true);
        axios.get('/api/directories?parentId=null')
            .then(res => {
                setDirectories(res.data);
                setIsLoading(false); // Set isLoading to false after directories are loaded
            })
            .catch(error => {
                console.error("Failed to fetch directories", error);
                setIsLoading(false); // Also set isLoading to false in case of error to stop showing the loading indicator
            });
    }, []);

    const fetchData = () => {
        // Fetch loginVerifications
        fetch('/api/verify/loginVerificationsWithUsers', {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setLoginVerifications(data);
            })
            .catch(error => console.error('Error fetching login verifications:', error));
    
        // Fetch unassociatedUsers
        fetch('/api/adminUsers/getUnassociatedUsers', {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setUnassociatedUsers(data);
            })
            .catch(error => console.error('Error fetching unassociated users:', error));

        fetch('/api/adminUsers/getAllAdmins', {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setAllAdmins(data);
            })
            .catch(error => console.error('Error fetching admins:', error));
    };

    const handleEditClick = (item) => {
        setEditeRowId(item.id);
        setEditFormData({ company_name: item.company_name, account_number: item.account_number });
    };

    const handleEditFormChange = (event) => {
        const { name, value } = event.target;
        setEditFormData({ ...editFormData, [name]: value });
    };

    const handleLoginVerificationSelect = (userId, loginVerificationId) => {
        setSelectedLoginVerification(prev => ({ ...prev, [userId]: loginVerificationId }));
    };

    // Add Unassign Button and Implement handleUnassignUser Function
    const handleUnassignUser = (userId) => {
        fetch(`/api/adminUsers/unassignUser/${userId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            // backend returns the unassigned user
            const unassignedUser = data;

            // Update loginVerifications to remove the user from the users array
            const updatedLoginVerifications = loginVerifications.map(lv => {
                return {
                    ...lv,
                    users: lv.users.filter(user => user.id !== userId),
                };
            });

            // Add the unassigned user to unassociatedUsers
            const updatedUnassociatedUsers = [...unassociatedUsers, unassignedUser];

            // Update state
            setLoginVerifications(updatedLoginVerifications);
            setUnassociatedUsers(updatedUnassociatedUsers);
            
            fetchData();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleConfirmClick = () => {
        // Check if there are changes
        const originalItem = loginVerifications.find(item => item.id === editRowId);
        if (originalItem.company_name === editFormData.company_name && originalItem.account_number === editFormData.account_number) {
            console.log('No changes detected.');
            setEditeRowId(null); // Reset edit mode
            return; // Exit the function early
        }

        const updatedLoginVerficiations = loginVerifications.map((item) => {
            if (item.id === editRowId) {
                return { ...item, ...editFormData };
            }
            return item;
        });
        setLoginVerifications(updatedLoginVerficiations);
        setEditeRowId(null);

        // Submit the changes to the backend
        fetch(`/api/adminUsers/updateLoginVerification/${editRowId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editFormData),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleAssignUser = (userId) => {
        const loginVerificationId = selectedLoginVerification[userId];
        if (!loginVerificationId) {
            console.error('No login verification ID selected');
            return;
        }
    
        fetch(`/api/adminUsers/updateUserLoginVerificationId/${userId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ loginVerificationId }), 
        })
        .then(response => response.json())
        .then(data => {
            console.log('Raw Server Response:', data);
            fetchData();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleDeleteUserConfirmation = (userId) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to delete this user?");
        if (isConfirmed) {
            handleDeleteUser(userId);
        }
    };

    const handleDeleteUser = (userId) => {
        fetch(`/api/adminUsers/deleteUser/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            console.log('Raw Server Response:', data);
            fetchData();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleCreateUser = () => {
        const endpoint = userType === 'Admin' ? '/api/adminUsers/createAdmin' : '/api/adminUsers/createUser';

        const data = { email, userType };
        if (userType === 'Regular User' && company) {
            data.company = company;
        }

        fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            console.log('User created:', data);
            alert(`email sent successfully.`);
            setEmail('');
            setUserType('Regular User');
            setCompany('');
            fetchData();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const generateTempPassword = (userId) => {
        fetch(`/api/adminUsers/generateTempPassword/${userId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            alert(`Temporary password for user ID ${userId}: ${data.tempPassword}`);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleDirectoryClick = (id) => {
        setSelectedDirectory(id);
    };

    const getFolderPath = async (folderId) => {
        let filePath = [];
        const fetchFolderDetails = async (id) => {
            try {
                const response = await axios.get(`/api/directories/findById/${id}`);
                // console.log(response);
                const folderData = response.data[0];
                filePath.unshift(folderData.dir_name);
                if (folderData.parentId) {
                    await fetchFolderDetails(folderData.parentId);
                }
            } catch (error) {
                console.error('Error fetching folder details:', error);
            }
        };

        await fetchFolderDetails(folderId);
        return filePath.join('/');
    };

    const handleDirectoryNameClick = (id) => {
        // console.log(id);
        Promise.all([
            axios.get(`/api/directories/findChildDirectories/${id}`), // Fetches only direct child directories
            axios.get(`/api/documents/directoryId/${id}`) // Fetches documents belonging to the selected directory
        ]).then(([resDirectories, resDocuments]) => {
            const combinedView = [
                ...resDirectories.data.map(dir => ({ ...dir, type: 'directory' })),
                ...resDocuments.data.map(doc => ({ ...doc, type: 'document' }))
            ];
            setCurrentView(combinedView); 
            fetch(`/api/directories/findById/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                // console.log(data[0]);
                setSelectedFolder(data[0]);
                setSelectedDeleteFile(null);

                getFolderPath(data[0].id).then((path) => {
                    // console.log('Path:', path);
                    setSelectedPath(path);
                    console.log('Selected Path:', path);
                });
            })
            .catch(error => console.error(error));
        }).catch(error => console.error(error));
    };

    const handleFileSelectDelete = (id, fileName) => {
        setSelectedDeleteFile({ id, fileName });
    };

    const getFileIcon = (fileName) => {
        if (!fileName) return null;
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <FaFilePdf />;
            case 'docx':
                return <FaFileWord />;
            default:
                return null; // Default case if no specific icon is needed
        }
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setSelectedFile(file);
        console.log('Selected file:', file);
        console.log('File name:', file.name);
        console.log('Selected path:', selectedPath);
    };

    const handleFileUpload = () => {
        if (!selectedFile) {
            alert('Please Select a file to upload');
            return; // Exit the function if no file is selected
        }

        let filePath = selectedPath + '/' + selectedFile.name;
        const formData = new FormData();
        formData.append('file', selectedFile); // This is the file content
        formData.append('filePath', filePath); // Adding filePath to the FormData

        console.log(formData.get('file'));

        fetch(`/api/adminUsers/uploadFile`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            console.log('Upload successful', data);
            // might want to implement a way to refresh the list or give feedback to the user
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        })
        .catch(error => {
            console.error('Upload error:', error);
        });
    };

    const handleDeleteFile = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this file?");
        if (isConfirmed) {
            let filePath = encodeURIComponent(selectedPath + '/' + selectedDeleteFile.fileName);
            // console.log('File path:', filePath);
            console.log('Selected delete fileName:', selectedDeleteFile.fileName);
            console.log(filePath);

            fetch(`/api/adminUsers/deleteFile/${filePath}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                // console.log('File deleted:', data);
                setSelectedDeleteFile(null);
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            })
            .catch(error => {
                console.error('Delete error:', error);
            });
        } else {
            // Abort deletion if not confirmed
            console.log('File deletion cancelled.');
        }
    };

    const handleAddCompany = () => {
        setShowAddCompanyModal(true);
    };

    const handleSaveCompany = () => {
        // Implement the logic to save the new company
        axios.post('/api/adminUsers/addCompany', {
            company_name: newCompanyName,
            account_number: newAccountNumber
        })
        .then(response => {
            // Handle successful response
            setShowAddCompanyModal(false);
            setNewCompanyName('');
            setNewAccountNumber('');
            fetchData();
        })
        .catch(error => {
            console.error('Error adding company:', error);
        });
    };

    const handleDeleteCompany = (companyId) => {
        const confirmed = window.confirm('Are you rusre you want to delete this company? This action cannot be undone.');

        if (confirmed) {
            axios.delete(`/api/adminUsers/deleteCompany/${companyId}`)
            .then(response => {
                console.log('Company deleted:', response.data);
                fetchData();
            })
            .catch(error => {
                console.error('Error deleting company:', error);
            });
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const directoriesForCurrentPage = directories.slice(startIndex, endIndex);

    return (
        <Layout>
            <div className="container admin-dashboard-container">
                <div className="admin-dashboard-panel">
                    <h2>Admin Dashboard</h2>
                    <p>Here is where you put the manage in managing</p>
                </div>
                <div className="admin-generator-panel">
                    <h2>User Generator</h2>
                    <p>Generate a new user here</p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Email (username)</th>
                                <th>User Type</th>
                                {userType === 'Regular User' && <th>Company</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input 
                                        type="text" 
                                        name="email" 
                                        placeholder="User Email" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select 
                                        name="userType" 
                                        value={userType}
                                        onChange={(e) => setUserType(e.target.value)}
                                    >
                                        <option value="Regular User">Regular User</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                </td>
                                {userType === 'Regular User' && (
                                    <td>
                                        <select 
                                            name="company"
                                            value={company}
                                            onChange={(e) => setCompany(e.target.value)}
                                        >
                                            <option value="">Select Company</option>
                                            {loginVerifications.map(company => (
                                                <option key={company.id} value={company.id}>{company.company_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                )}
                                <td>
                                    <button onClick={handleCreateUser} className="admin-button green">Generate</button>
                                    {/* <button onClick={handleTest} className="admin-button">Test</button> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="form-container-admin-home">
                    <div className="table-responsive">
                        <h3>Companies and Associated Users</h3>
                        {loginVerifications.map(item => (
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ marginRight: '10px' }}>
                                    {editRowId === item.id ? (
                                        <button onClick={handleConfirmClick} className="admin-button">Confirm</button>
                                    ) : (
                                        <button onClick={() => handleEditClick(item)} className="admin-button">Change</button>
                                    )}
                                    <button onClick={() => handleDeleteCompany(item.id)} className="admin-button red">Delete</button>
                                </div>
                                <table className="table border mb-0">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="column-id">ID</th>
                                            <th className="column-company-name">Company Name</th>
                                            <th className="column-account-number">Account Number</th>
                                            <th className="column-associated-users">Associated Users</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td className="column-id">{item.id}</td>
                                        <td className="column-company-name">
                                                {editRowId === item.id ? (
                                                    <input
                                                        type="text"
                                                        name="company_name"
                                                        value={editFormData.company_name}
                                                        onChange={handleEditFormChange}
                                                    />
                                                ) : (
                                                    item.company_name
                                                )}
                                            </td>
                                            <td className="column-account-number">
                                                {editRowId === item.id ? (
                                                    <input
                                                        type="text"
                                                        name="account_number"
                                                        value={editFormData.account_number}
                                                        onChange={handleEditFormChange}
                                                    />
                                                ) : (
                                                    item.account_number
                                                )}
                                            </td>
                                            <td className="column-associated-users">
                                                <ol>
                                                {item.users.map((user, index) => (
                                                    <li key={user.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{`${user.username} (ID: ${user.id})`}</span>
                                                        <button onClick={() => handleUnassignUser(user.id)} className="admin-button red">Unassign</button>
                                                        <button onClick={() => generateTempPassword(user.id)} className="admin-button">Generate Temp Password</button>
                                                    </li>
                                                ))}
                                                </ol>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={handleAddCompany} className="admin-button light-green">Add Company</button>
                        </div>
                        {/* Add Company Modal */}
                        <Modal show={showAddCompanyModal} onHide={() => setShowAddCompanyModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Company</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formCompanyName">
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            value={newCompanyName}
                                            onChange={(e) => setNewCompanyName(e.target.value)}
                                            placeholder="Enter company name"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Account Number</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            value={newAccountNumber}
                                            onChange={(e) => setNewAccountNumber(e.target.value)}
                                            placeholder="Enter account number"
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowAddCompanyModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompany}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className="unassociated-users-container">
                        <h3>User Jail</h3>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Username</th>
                                    <th>Assign to Company</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unassociatedUsers.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.username}</td>
                                        <td>
                                            <select onChange={(e) => handleLoginVerificationSelect(user.id, e.target.value)} value={selectedLoginVerification[user.id] || ''}>
                                                <option value="">Select Company</option>
                                                {loginVerifications.map(company => (
                                                    <option key={company.id} value={company.id}>{company.company_name}</option>
                                                ))}
                                            </select>
                                            <button onClick={() => handleAssignUser(user.id)} className="admin-button">Assign</button>
                                            <button onClick={() => handleDeleteUserConfirmation(user.id)} className="admin-button black">
                                                Delete <FontAwesomeIcon icon={faSkull} />
                                            </button>
                                            <button onClick={() => generateTempPassword(user.id)} className="admin-button">Generate Temp Password</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="form-container-admin-home">
                    <div className="table-responsive">
                        <h3>Admin Users</h3>
                        {allAdmins.map(item => (
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <table className="table border mb-0">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="column-id">ID</th>
                                            <th className="column-username">Username</th>
                                            <th className="column-role">Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="column-id">{item.id}</td>
                                            <td className="column-username">{item.username}</td>
                                            <td className="column-role">{item.role}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form-container-admin-home">
                    <div className="col_sm-12">
                        <div className="form-container-admin-documents">
                            <h2>Edit Documents</h2>
                            <input type="file" onChange={handleFileSelect} />
                            <button onClick={handleFileUpload} className="upload-button">Upload File</button>
                            <p>You will upload file to: {selectedFolder ? selectedFolder.dir_name : 'No folder selected'}</p>
                            {/* <p>{selectedPath}</p> */}
                            <button onClick={handleDeleteFile} className="admin-button red delete">Delete File</button>
                            <p>You will delete file: {selectedDeleteFile && <p>File Name: {selectedDeleteFile.fileName}</p>}</p>
                            <Form>
                                <div className="col-sm-12">
                                    <div className="container doc-tableordered">
                                        <div className="row">
                                            <div className="col-sm-3 document-height directory-tree-text-left" style={{borderRight: '1px solid #7F7F7F', verticalAlign: 'top', height: '586px', paddingRight: '0px', overflow: 'scroll'}}>
                                            {/* {directoriesForCurrentPage.filter(directory => directory.parentId === null).map(directory => (
                                                <DirectoryTree key={directory.id} directory={directory} onClick={handleDirectoryClick} isRoot={true} />
                                            ))} */}
                                            {directoriesForCurrentPage.filter(directory => directory.parentId === null).map(directory => (
                                                <DirectoryTree key={directory.id} directory={directory} onClick={handleDirectoryClick} onDirectoryNameClick={handleDirectoryNameClick} isRoot={true} depth = {0} />
                                            ))}
                                            </div>
                                            <div className="col-sm-9" style={{overflow: 'auto'}}>
                                                <div className="row" style={{marginBottom: '10px'}}>

                                                </div>
                                                <div className="row">
                                                    <div id="documentFiles">
                                                        <div style={{ height: '525px', overflow: 'scroll' }}>
                                                            <table className="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Date</th>
                                                                        <th>Size</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {currentView.length > 0 ? (
                                                                        currentView.map((item, index) => (
                                                                            <tr key={`${item.type}-${item.id}-${index}`} style={{ cursor: 'pointer' }}>
                                                                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {item.type === 'directory' ? (
                                                                                        <>
                                                                                            <FaFolder style={{ marginRight: '5px' }} />
                                                                                            <div onClick={() => handleDirectoryNameClick(item.id)} style={{ cursor: 'pointer', flexGrow: 1 }}>{item.dir_name}</div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleFileSelectDelete(item.id, item.file_name)} >
                                                                                            {getFileIcon(item.file_name)}
                                                                                            <div style={{ marginLeft: '5px' }}>{item.file_name}</div>
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {item.type === 'document' ? new Date(item.uploadDate).toLocaleDateString() : ''}
                                                                                </td>
                                                                                <td>
                                                                                    {item.type === 'document' ? `${(item.size / 1024).toFixed(2)} KB` : ''}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        // Display a message when there are no items to display
                                                                        <tr><td colSpan="3" style={{ textAlign: 'center' }}>Select a folder from the left or the folder icon for a folder dropdown</td></tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AdminDashboard;