import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from './layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import './css/createPassword.css';

const CreatePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const { token, username } = useParams();

    const navigate = useNavigate();

    const checkPassword = (password) => {
        // At least 8 characters long, contains a number, a lowercase letter, and an uppercase letter, and a special character
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return regex.test(password);
    };

    const settingPassword = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        if (!checkPassword(password)) {
            alert('Password must be at least 8 characters long, contain a number, a lowercase letter, an uppercase letter, and a special character.');
            return;
        }

        try {
            const response = await fetch(`/api/users/setPassword/${token}/${username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ newPassword: password })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.success) {
                alert('Password set successfully');
                navigate('/');
            } else {
                alert('Password set failed');
            }
        } catch {
            try {
                const response = await fetch(`/api/adminUsers/setPassword/${token}/${username}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ newPassword: password })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
    
                if (data.success) {
                    alert('Password set successfully');
                } else {
                    alert('Password set failed');
                }
            } catch {
                console.error('Error setting password');
                // Handle error - show an error message to the user
            }
            console.error('Error setting password');
            // Handle error - show an error message to the user
        }
    };

    return (
        <Layout>
            <div className="container create-password-container">
                <div className="form-container form-container-create-password">
                    <div className="create-password-inner-container">
                        <h1>Create Password</h1>
                        <p>Please create a password for your account.</p>
                        <p>Password must be at least 8 characters long, contain a number, a lowercase letter, an uppercase letter and a special character.</p>
                        <Form onSubmit={settingPassword}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <div style={{ position: 'relative', width: '50%' }}>
                                    <Form.Control type={newPasswordVisible ? "text" : "password"} placeholder="Enter your new password" onChange={(e) => setPassword(e.target.value)} />
                                    <FontAwesomeIcon 
                                        icon={faEye} 
                                        onClick={() => setNewPasswordVisible(!newPasswordVisible)} 
                                        style={{ cursor: 'pointer', position: 'absolute', zIndex: 999, right: '5px', top: '-20px', border: '0px', background: 'none' }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword"> {/* Add this Form.Group */}
                                <Form.Label>Confirm Password</Form.Label>
                                <div style={{ position: 'relative', width: '50%' }}>
                                    <Form.Control type={confirmPasswordVisible ? "text" : "password"} placeholder="Confirm your new password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <FontAwesomeIcon 
                                        icon={faEye} 
                                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} 
                                        style={{ cursor: 'pointer', position: 'absolute', zIndex: 999, right: '5px', top: '-20px', border: '0px', background: 'none' }}
                                    />
                                </div>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Create Password
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreatePassword;