import React, { useState } from 'react';
import Layout from './layout';
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import './css/passwordResetPage.css';

function PasswordResetPage() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const { token, username } = useParams();

    const navigate = useNavigate();

    const checkPassword = (password) => {
        // At least 8 characters long, contains a number, a lowercase letter, and an uppercase letter, and a special character
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return regex.test(password);
    };

    const resetPassword = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        if (!checkPassword(password)) {
            alert('Password must be at least 8 characters long, contain a number, a lowercase letter, an uppercase letter, and a special character.');
            return;
        }

        try {
            const response = await fetch(`/api/users/resetPassword/${token}/${username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ newPassword: password })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.success) {
                alert('Password reset successfully');
                navigate('/');
            } else {
                alert('Password reset failed');
            }
        } catch {
            console.error('Error resetting password');
            // Handle error - show an error message to the user
        }
    };

    return (
        <Layout>
            <div className="container password-reset-container">
                <div className="form-container form-container-password-reset">
                    <div className="password-reset-inner-container">
                        <h1>Reset Password</h1>
                        <p>Please enter a new password in the form below. An email confirmation will be sent to you when completed.</p>
                        <p>Password must be at least 8 characters long, contain a number, a lowercase letter, an uppercase letter, and a special character.</p>
                        <Form onSubmit={resetPassword}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <div style={{ position: 'relative', width: '50%' }}>
                                    <Form.Control type={newPasswordVisible ? "text" : "password"} placeholder="Enter your new password" onChange={(e) => setPassword(e.target.value)} />
                                    <FontAwesomeIcon 
                                        icon={faEye} 
                                        onClick={() => setNewPasswordVisible(!newPasswordVisible)} 
                                        style={{ cursor: 'pointer', position: 'absolute', zIndex: 999, right: '5px', top: '-20px', border: '0px', background: 'none' }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="confirmNewPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <div style={{ position: 'relative', width: '50%' }}>
                                    <Form.Control type={confirmPasswordVisible ? "text" : "password"} placeholder="Confirm your new password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <FontAwesomeIcon 
                                        icon={faEye} 
                                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} 
                                        style={{ cursor: 'pointer', position: 'absolute', zIndex: 999, right: '5px', top: '-20px', border: '0px', background: 'none' }}
                                    />
                                </div>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Reset Password
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PasswordResetPage;