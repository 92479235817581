// documents.js frontend document component

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import { Form } from 'react-bootstrap';
import DirectoryTree from './directoryTree';
import './css/documents.css';
import { FaFilePdf, FaFileWord, FaFolder } from 'react-icons/fa';

const Documents = () => {
    const [directories, setDirectories] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Change this to the number of items you want per page
    const [currentView, setCurrentView] = useState([]); // New state to manage the current view
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Initial fetch for root directories
        axios.get('/api/directories?parentId=null').then(res => {
            setDirectories(res.data);
            // setCurrentView(res.data); // Set initial view to root directories
            setIsLoading(false);
        });
    }, []);

    const handleDirectoryClick = (id) => {
        setSelectedDirectory(id);
        // Fetch documents for this directory
        // axios.get(`http://localhost:4000/api/documents?directoryId=${id}`).then(res => setDocuments(res.data));
    };

    const handleDirectoryNameClick = (id) => {
        // console.log(id);
        Promise.all([
            axios.get(`/api/directories/findChildDirectories/${id}`), // Fetches only direct child directories
            axios.get(`/api/documents/directoryId/${id}`) // Fetches documents belonging to the selected directory
        ]).then(([resDirectories, resDocuments]) => {
            // Combine directories and documents, marking each with a type for rendering
            // console.log(resDirectories.data)
            const combinedView = [
                ...resDirectories.data.map(dir => ({ ...dir, type: 'directory' })),
                ...resDocuments.data.map(doc => ({ ...doc, type: 'document' }))
            ];
            setCurrentView(combinedView); // Update the view to only show direct children
        }).catch(error => console.error(error));
    };

    const handleFileDownload = async (id, fileName) => {
        try {
            const response = await fetch(`/api/documents/download/${id}`, {
                method: 'GET',
            });
            if (!response.ok) throw new Error('Network response was not ok');
    
            const blob = await response.blob();
    
            // Extract file extension from fileName
            const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
    
            // Create a link to download the blob
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName ? fileName : `downloaded-file${fileExtension}`); // Use the original file name or default to 'downloaded-file' with the extracted extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Clean up
        } catch (error) {
            console.error('Download error:', error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate start and end indices for slicing the directories array
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the directories array to get only the items for the current page
    const directoriesForCurrentPage = directories.slice(startIndex, endIndex);

    // Function to determine and return the appropriate icon component based on file extension
    const getFileIcon = (fileName) => {
        if (!fileName) return null;
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <FaFilePdf />;
            case 'docx':
                return <FaFileWord />;
            default:
                return null; // Default case if no specific icon is needed
        }
    };

    return (
        <Layout>
            <div className="container">
                <div className="col-sm-12">
                    <h1>Document Library</h1>
                </div>
                <div className="col-sm-12">
                    <p>
                    To view Documents, select a category from the list below, and click on the Document Title to download the file. Documents are often formatted in Adobe PDF and will download and open automatically if you have Adobe Reader.
                    </p>
                </div>
                <div className="col_sm-12">
                    <Form>
                        <div className="col-sm-12">
                            <div className="container doc-tableordered">
                                <div className="row">
                                    <div className="col-sm-3 document-height directory-tree-text-left" style={{borderRight: '1px solid #7F7F7F', verticalAlign: 'top', height: '586px', paddingRight: '0px', overflow: 'scroll'}}>
                                    {/* {directoriesForCurrentPage.filter(directory => directory.parentId === null).map(directory => (
                                        <DirectoryTree key={directory.id} directory={directory} onClick={handleDirectoryClick} isRoot={true} />
                                    ))} */}
                                    {directoriesForCurrentPage.filter(directory => directory.parentId === null).map(directory => (
                                        <DirectoryTree key={directory.id} directory={directory} onClick={handleDirectoryClick} onDirectoryNameClick={handleDirectoryNameClick} isRoot={true} depth = {0} />
                                    ))}
                                    </div>
                                    <div className="col-sm-9" style={{overflow: 'auto'}}>
                                        <div className="row" style={{marginBottom: '10px'}}>

                                        </div>
                                        <div className="row">
                                            <div id="documentFiles">
                                                <div style={{ height: '525px', overflow: 'scroll' }}>
                                                    <table className="custom-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Date</th>
                                                                <th>Size</th>
                                                            </tr>
                                                        </thead>
                                                        {/* <tbody>
                                                            {currentView.map((item, index) => (
                                                            <tr key={`${item.type}-${item.id}-${index}`} style={{ cursor: 'pointer' }}>
                                                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.type === 'directory' ? (
                                                                        <>
                                                                            <FaFolder style={{ marginRight: '5px' }} />
                                                                            <div onClick={() => handleDirectoryNameClick(item.id)} style={{ cursor: 'pointer', flexGrow: 1 }}>{item.dir_name}</div>
                                                                        </>
                                                                    ) : (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleFileDownload(item.id, item.file_name)}>
                                                                            {getFileIcon(item.file_name)}
                                                                            <div style={{ marginLeft: '5px' }}>{item.file_name}</div>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.type === 'document' ? new Date(item.uploadDate).toLocaleDateString() : ''}
                                                                </td>
                                                                <td>
                                                                {item.type === 'document' ? `${(item.size / 1024).toFixed(2)} KB` : ''}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody> */}
                                                        <tbody>
                                                            {currentView.length > 0 ? (
                                                                currentView.map((item, index) => (
                                                                    <tr key={`${item.type}-${item.id}-${index}`} style={{ cursor: 'pointer' }}>
                                                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {item.type === 'directory' ? (
                                                                                <>
                                                                                    <FaFolder style={{ marginRight: '5px' }} />
                                                                                    <div onClick={() => handleDirectoryNameClick(item.id)} style={{ cursor: 'pointer', flexGrow: 1 }}>{item.dir_name}</div>
                                                                                </>
                                                                            ) : (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleFileDownload(item.id, item.file_name)}>
                                                                                    {getFileIcon(item.file_name)}
                                                                                    <div style={{ marginLeft: '5px' }}>{item.file_name}</div>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {item.type === 'document' ? new Date(item.uploadDate).toLocaleDateString() : ''}
                                                                        </td>
                                                                        <td>
                                                                            {item.type === 'document' ? `${(item.size / 1024).toFixed(2)} KB` : ''}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                // Display a message when there are no items to display
                                                                <tr><td colSpan="3" style={{ textAlign: 'center' }}>Select a folder from the left or the folder icon for a folder dropdown</td></tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    )
};
export default Documents;